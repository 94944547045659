


















































































































import { dateFormatFilter } from '@/helpers/fiters';
import Vue from 'vue';

export default Vue.extend({
  name: "HomeMain2d",
  data: () => {
    return {
      memberInfo: {},
      canAdmin: false,
      loaded: false,

    };
  },

  methods: {
    async getData() {

      try {
        const response = await this.$http.get("/member/info/"+this.$store.data.place.member_id);
        this.memberInfo = response.data.memberInfo;
      } catch (error) {
        console.log(error);
      }
    },
    async checkAdmin() {
      try {
        await this.$http.get(
          `/block/${  this.$store.data.place.block.id  }/can_admin`,
        );
        this.canAdmin = true;
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    this.getData();
    this.checkAdmin();
  },
  watch: {
    "$store.data.place.block": {
      handler() {
        if (this.$route.params.username) {
          this.loaded = true;
          this.checkAdmin();
        }
      },
    },
  },
});
